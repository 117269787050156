<template>
  <el-dialog :title="title"
             :visible.sync="outerVisible"
             @close="close" width="93%">

    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="리그기본설정" name="leagueInfo1">

        <table class="table100">
          <tr>
            <th colspan="2">리그이미지</th>
            <th colspan="6">리그이미지 등록</th>
          </tr>
          <tr>
            <td colspan="2">
              <img :src="league.imageUrlTeamp"
                   style="width: 28px;max-height: 20px;vertical-align: middle">
            </td>
            <td colspan="6">
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  action="#"
                  :http-request="httpRequest"
                  :before-upload="beforeUpload"
                  :on-change="fileChanged"
                  :file-list="fileList"
                  :show-file-list="false"
              >
                <el-button slot="trigger" size="small" type="primary">이미지선택
                </el-button>
                <el-button
                    style="margin-left: 10px"
                    size="small"
                    type="success"
                    @click="submitUpload(league)"
                >이미지등록
                </el-button>

              </el-upload>
            </td>
          </tr>
          <tr>
            <th style="width: 12%">종목</th>
            <th style="width: 12%">리그명</th>
            <th style="width: 12%">정열순위</th>
            <th style="width: 12%">상태</th>
            <th style="width: 12%">경기자동등록</th>
            <th style="width: 12%">Inplay자동등록</th>
            <th style="width: 12%">인기리그여부</th>
            <th style="width: 4%">색상</th>
          </tr>
          <tr>
            <td>
              <el-select size="mini" style="width: 150px;margin-left: 5px" v-model="league.gameTypeId"
                         placeholder="종목별">
                <el-option v-for="item in gameTypeList" :value="item.id" :label="item.typeName">{{ item.typeName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-input size="mini"
                        v-model="league.leagueName"></el-input>
            </td>
            <td>
              <el-input size="mini"
                        v-model="league.sort"></el-input>
            </td>
            <td>
              <el-select size="mini" v-model="league.status" placeholder="상태"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.autoable" placeholder="경기자동등록여부"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="예,자동등록">예,자동등록</el-option>
                <el-option :value="managerConst.DISABLE" label="아니오,수동">아니오,수동</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.inplayable" placeholder="Inplay자동등록여부"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="예,자동등록">예,자동등록</el-option>
                <el-option :value="managerConst.DISABLE" label="아니오,수동">아니오,수동</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.popular" placeholder="인기리그여부"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="인기">인기</el-option>
                <el-option :value="managerConst.DISABLE" label="일반">일반</el-option>
              </el-select>
            </td>
            <td>
              <el-color-picker size="mini" v-model="league.leagueColor"></el-color-picker>
            </td>
          </tr>
          <tr>
            <th style="width: 12%">풀타임*배당(승무패)</th>
            <th style="width: 12%">풀타임*배당(핸디)</th>
            <th style="width: 12%">풀타임*배당(오언)</th>
            <th style="width: 12%">하프*배당(승무패)</th>
            <th style="width: 12%">하프*배당(핸디)</th>
            <th style="width: 12%">하프*배당(오언)</th>
            <th style="width: 12%"></th>
            <th style="width: 4%"></th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.extraodds"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.extraodds1"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.extraodds2"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.specextraodds1"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.specextraodds2"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.specextraodds3"></el-input>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th style="width: 12%">핸디최소배당(풀타임)</th>
            <th style="width: 12%">오언최소배당(풀타임)</th>
            <th style="width: 12%">핸디최소배당(하프타임)</th>
            <th style="width: 12%">오언최소배당(하프타임)</th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
            <th style="width: 4%"></th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.fhlowodds"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.foulowodds"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.hhlowodds"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.houlowodds"></el-input>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th style="width: 12%">(풀타임)1x2경기상태</th>
            <th style="width: 12%">(풀타임)핸디캡경기상태</th>
            <th style="width: 12%">(풀타임)오버언더경기상태</th>
            <th style="width: 12%">(하프타임)1x2경기상태</th>
            <th style="width: 12%">(하프타임)핸디캡경기상태</th>
            <th style="width: 12%">(하프타임)오버언더경기상태</th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
          </tr>
          <tr>
            <td>
              <el-select size="mini" v-model="league.sportsfulltime1x2status" placeholder="(풀타임)1x2경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.sportsfulltimehandicapstatus" placeholder="(풀타임)핸디캡경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.sportsfulltimeoverunderstatus" placeholder="(풀타임)오버언더경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.sportshalftime1x2status" placeholder="(하프타임)1x2경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.sportshalftimehandicapstatus" placeholder="(하프타임)핸디캡경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="league.sportshalftimeoverunderstatus" placeholder="(하프타임)오버언더경기상태"
                         style="width: 80%">
                <el-option :value="managerConst.Game.STATUS_OPEN" label="베팅가능">베팅가능</el-option>
                <el-option :value="managerConst.Game.STATUS_CHECKING" label="베팅대기">베팅대기</el-option>

              </el-select>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th style="width: 12%">(풀타임)핸디캡 최소배당차이 등록개수</th>
            <th style="width: 12%">(풀타임)오버언더 최소배당차이 등록개수</th>
            <th style="width: 12%">(하프타임)핸디캡 최소배당차이 등록개수</th>
            <th style="width: 12%">(하프타임)오버언더 최소배당차이 등록개수</th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
            <th style="width: 12%"></th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.hdmingapcount"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.oumingapcount"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.hdmingapspeccount"></el-input>
            </td>
            <td>
              <el-input size="mini" type="number"
                        v-model="league.oumingapspeccount"></el-input>
            </td>
          </tr>


        </table>
      </el-tab-pane>

      <el-tab-pane label="경기구역설정" name="leagueInfo2">
        <el-radio border size="mini" :label="item.areaName" style="margin: 2px 2px"
                  v-model="league.areaName"
                  v-for="(item,index) in matchAreaList">{{ item.areaName }}
        </el-radio>


      </el-tab-pane>


    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <el-button @click="save()">저장하기</el-button>
      <el-button @click="outerVisible = false">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
import managerConst from "../../../common/administrator/managerConst";
import {getLeagueById, getMatchAreaList, saveLeague, updateLeague} from "../../../network/manager/leagaueRequest";
import {getGameTypeList} from "../../../network/manager/gameTypeRequest";
import {Loading} from "element-ui";
import {uploadFile} from "../../../network/manager/commonRequest";

export default {
  name: "LeagueEditToast",
  components: {},
  data() {
    return {
      title: '',
      leagueId: null, //
      league: {areaName: null},
      closed: false,
      outerVisible: false,
      managerConst: managerConst,
      mode: managerConst.MODE_SAVE, //save:등록 ,edit:수정,
      matchAreaList: [],
      leagueList: [],
      activeName: 'leagueInfo1',
      gameTypeList: [],
      actionUrl: '/upload_image_4',
      fileList: [],
      file: null,
    }
  },
  methods: {
    search() {

    },
    close() {
      this.league = {}
      this.leagueId = null
      this.closed = true
      this.outerVisible = false
    },
    destroyElement() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    save() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.mode === managerConst.MODE_SAVE) {
        saveLeague(this.league).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '리그 등록이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('leagueEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
          loadingInstance.close()
        })
      }
      if (this.mode === managerConst.MODE_EDIT) {
        updateLeague(this.league).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '리그수정이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('leagueEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
          loadingInstance.close()
        })
      }
    },
    getLeagueById() {
      getLeagueById(this.leagueId).then(res => {
        this.league = res.data.data
        this.$set(this.league, 'imageUrlTeamp', this.league.imageUrl)
        this.league.imageUrl = null
        this.title = '리그 [' + this.league.leagueName + '] 상세정보'
      })
    },
    async submitUpload(league) {
      // 如果上传的文件改变了，执行文件上传方法获取新的地址
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      uploadFile(this.actionUrl, this.file).then(res => {
        if (res.data.success) {
          let uploadedFileName = res.data.data.uploadedFileName;
          let fullpath = res.data.data.fullpath;
          league.imageUrl = uploadedFileName
          if (this.mode == managerConst.MODE_EDIT) {
            updateLeague(league).then(res => {
              this.getLeagueById()
              loadingInstance.close()
            })
          } else {
            this.$message({
              type: 'success',
              duration: 1000,
              message: '이미지 등록이 완료되였습니다'
            });
            loadingInstance.close()
          }
        } else {
          this.$message({
            type: 'success',
            duration: 2000,
            message: res.data.msg
          });
          loadingInstance.close()
        }
      })
    },
    // 覆盖默认行为
    httpRequest() {
      this.imgChange = true;
    },
    fileChanged(file, fileList) {
      this.file = file.raw;
    },
    // 上传之前
    beforeUpload(file) {

    },
  },
  created() {
    this.outerVisible = true
    if (this.mode === managerConst.MODE_EDIT) {
      this.getLeagueById()
    }
    getMatchAreaList().then(res => {
      this.matchAreaList = res.data.data
    })
    getGameTypeList().then(res => {
      this.gameTypeList = res.data.data
    })
  },
  mounted() {

  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.close()
        this.destroyElement()
      }
    }
  },

}
</script>

<style scoped>


.table100 td {
  padding: 5px;
  background-color: #cfcfd1 !important;
}

.table100 th {
  width: 20%;
}

.table100 .el-input__inner {
  width: 80% !important;
}

.table_userstatisc {
  width: 100%;
  margin-bottom: 5px;
}

.table_userstatisc th {
  width: 14%;
}

.table_userstatisc td {
  background-color: #e8e8e8 !important;
  width: 14%;
}

</style>